@import "../../../assets/scss/variables.scss";

.home-header-wrapper {
    position: absolute;
    z-index: 1;
    width: 100%;
    background: $dark-color;

    .home-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 25px;
        max-width: 1400px;
        margin: auto;

        @media screen and (max-width:1199px) {
            padding: 14px 16px;
            justify-content: space-between;
        }

        @media screen and (max-width:640px) {
            padding: 14px 8px;
        }

        & .logo-wrapper {
            display: inline-flex;
            align-items: flex-start;
            justify-content: flex-start;

            & .logo {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                a {
                    display: flex;
                    align-items: center;
                    color: $white;
                    font-weight: 600;
                    font-size: 1rem;
                }

                & svg {
                    fill: $white;
                    width: 45px;
                    height: auto;
                    margin-right: 10px;
                    fill: linear-gradient(180deg, #E2F7E5 0%, #52B788 100%);
                }
            }
        }

        & .header-right {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            .ant-btn-primary {
                padding: 0 10px;
            }

            .launch-btn{
                .ant-btn {
                    @media screen and (max-width:767px) {
                        padding: 0 1.2rem;
                        font-size: 13px;
                        height: 36px;
                    }
                }
            }

            .header-menus {
                margin-left: auto;
                text-align: center;
                display: flex;
                align-items: center;
                padding-right: 2rem;

                @media screen and (max-width:676px) {
                    display: none;
                }

                a {
                    color: $text-light;
                    padding: 0 15px;
                    font-weight: 500;
                    color: $white;

                    &.active {
                        color: $white;
                    }
                }

            }
        }

        .siderbar-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            position: absolute;
            right: 0;
            top: 19px;
            z-index: 2;

            .icon {
                fill: $white;
                width: 50px !important;
            }
        }
    }
}