@import "../../assets/scss/variables.scss";

.home-wrapper {
    width: 100%;
    max-height: 100vh;
    overflow: hidden;

    .banner-video {
        width: auto;
        min-width: 100%;
        min-height: 100vh;
        position: relative;
        opacity: 0.2;
        z-index: 0;
        margin: auto;
    }

    .home-slider {
        position: absolute;
        top: 0;
        width: 100vw;
        height: 100vh;
        max-width: 100vw;
        max-height: 100vh;
        overflow: hidden;

        .slick-prev {
            left: 100px;
            bottom: 50px;
            top: auto;

            @media screen and (max-width:1600px) {
                bottom: 30px;
            }

            &::before {
                display: none;
            }

            .icon {
                fill: $secondary-color;
                width: 36px;
                height: 36px;
            }

            @media screen and (max-width:767px) {
                left: 20px;
                bottom: 20px;
            }
        }

        .slick-next {
            right: 100px;
            bottom: 50px;
            top: auto;

            @media screen and (max-width:1600px) {
                bottom: 30px;
            }

            &::before {
                display: none;
            }

            .icon {
                fill: $secondary-color;
                width: 36px;
                height: 36px;
            }

            @media screen and (max-width:767px) {
                right: 30px;
                bottom: 20px;
            }
        }

        .slick-arrow {
            &.slick-disabled {
                opacity: 0.5;
                cursor: default;
            }
        }

        .slick-slide {
            height: calc(100vh - 70px);

            @media screen and (max-width:767px) {
                padding-top: 70px;
                height: 100vh;
            }
        }

        .slide-1 {
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            max-width: 1410px;
            margin: auto;
            width: 90%;

            h1 {
                font-size: 4.8rem;
                margin-bottom: 1rem;

                @media screen and (max-width:1440px) {
                    font-size: 4rem;
                }

                @media screen and (max-width:1240px) {
                    font-size: 3.4rem;
                }

                @media screen and (max-width:767px) {
                    font-size: 2.4rem;
                }

                @media screen and (max-width:576px) {
                    font-size: 1.8rem;
                }
            }

            p {
                font-weight: 300;
                font-size: 1.9rem;
                max-width: 900px;
                display: flex;
                align-items: center;

                @media screen and (max-width:767px) {
                    font-size: 1rem;
                }

                .icon {
                    width: 135px;
                    margin-left: 12px;
                }
            }

            .sub-title {
                font-weight: 300;
                letter-spacing: 8px;
            }

            .ant-btn {
                margin: 0 8px 10px 8px;
            }
        }

        .slide-2 {
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            max-width: 1410px;
            margin: auto;
            width: 90%;

            @media screen and (max-width:1500px) {
                max-width: 1050px;
            }

            @media screen and (max-width:991px) {
                justify-content: flex-start;
                max-height: calc(100vh - 80px);
                overflow-y: auto;
                padding-top: 80px;
            }

            h1 {
                font-size: 3rem;
                margin-bottom: 3rem;

                @media screen and (max-width:1500px) {
                    font-size: 2.5rem;
                    margin-bottom: 2rem;
                }

                @media screen and (max-width:1240px) {
                    font-size: 2rem;
                    margin-bottom: 2rem;
                }

                @media screen and (max-width:576px) {
                    font-size: 1.8rem;
                }
            }

            ul {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 2rem;
                list-style-type: none;
                padding-left: 0;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                text-align: center;

                @media screen and (max-width:991px) {
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 1rem;
                }

                @media screen and (max-width:576px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                li {
                    &.circle-items {
                        width: 380px;
                        height: 380px;
                        border-radius: 380px;
                        padding: 7px;
                        border: 6px solid $secondary-color;
                        margin: auto;
                        transition: all ease-in 0.15s;

                        @media screen and (max-width:1600px) {
                            width: 340px;
                            height: 340px;
                            border-radius: 340px;
                        }

                        @media screen and (max-width:1240px) {
                            width: 280px;
                            height: 280px;
                        }

                        &:last-child {
                            @media screen and (max-width:991px) {
                                grid-column: 1 / 3;
                            }

                            @media screen and (max-width:576px) {
                                grid-column: 1 / 1;
                            }
                        }

                        @media screen and (max-width:767px) {
                            width: 220px;
                            height: 220px;
                            border: 4px solid $secondary-color;
                        }

                        @media screen and (max-width:576px) {
                            width: 180px;
                            height: 180px;
                        }

                        .circle-items-inner {
                            background: rgba(232, 232, 232, 0.1);
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            position: relative;

                            .circle-icon {
                                position: absolute;
                                top: -20px;
                                left: 40px;
                                width: 70px;
                                height: 70px;
                                border-radius: 70px;
                                background: $secondary-color;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 10px;

                                @media screen and (max-width:1240px) {
                                    width: 60px;
                                    height: 60px;
                                    border-radius: 60px;
                                    left: 0;
                                    top: 0;
                                }

                                .icon {
                                    width: 100%;
                                    max-height: 46px;
                                }

                                @media screen and (max-width:767px) {
                                    width: 30px;
                                    height: 30px;

                                    .icon {
                                        width: 15px;
                                    }
                                }
                            }

                            h3 {
                                font-size: 1.8rem;
                                font-weight: 600;
                                color: $white;

                                @media screen and (max-width:1600px) {
                                    font-size: 1.5rem;
                                }

                                @media screen and (max-width:1240px) {
                                    font-size: 1.2rem;
                                }

                                @media screen and (max-width:767px) {
                                    font-size: 1.1rem;
                                }
                            }

                            p {
                                font-size: 20px;
                                font-weight: 300;
                                margin-bottom: 0;
                                padding: 0 10px;

                                @media screen and (max-width:1600px) {
                                    font-size: 16px;
                                }

                                @media screen and (max-width:1240px) {
                                    font-size: 16px;
                                }

                                @media screen and (max-width:767px) {
                                    font-size: 12px;
                                    line-height: 1.1;
                                }
                            }
                        }

                        &:hover {
                            border-color: $success-color;

                            .circle-icon {
                                background: $success-color;
                                transition: all ease-in 0.15s;

                                .icon {
                                    fill: $white;
                                    transition: all ease-in 0.15s;
                                }
                            }
                        }
                    }
                }
            }
        }

        .slide-3 {
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            max-width: 1100px;
            margin: auto;
            width: 90%;

            @media screen and (max-width:1500px) {
                max-width: 1050px;
            }

            .ant-divider {
                @media screen and (max-width:991px) {
                    margin: 10px 0;
                }
            }

            h1 {
                font-size: 3.5rem;
                margin-bottom: 0.7rem;

                @media screen and (max-width:1500px) {
                    font-size: 2.5rem;
                    margin-bottom: 0.2rem;
                }

                @media screen and (max-width:920px) {
                    font-size: 2rem;
                }

                @media screen and (max-width:576px) {
                    font-size: 1.8rem;
                }
            }

            p {
                font-weight: 300;
                font-size: 1.6rem;
                max-width: 680px;
                line-height: 1.2;
            }

            .how-work-wrapper {
                width: 100%;

                .upper-lines {
                    @media screen and (max-width:920px) {
                        display: none;
                    }

                    .icon {
                        width: 900px;
                        max-width: 75%;

                        @media screen and (max-width:1500px) {
                            max-width: 80%;
                        }
                    }
                }

                .howwork-row {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    @media screen and (max-width:920px) {
                        display: block;
                    }

                    .howwork-col1 {
                        width: 100%;
                        max-height: 400px;
                        width: 400px;
                        position: relative;

                        @media screen and (max-width:1500px) {
                            max-height: 350px;
                            width: 350px;
                        }

                        @media screen and (max-width:1400px) {
                            max-height: 320px;
                            width: 320px;
                        }

                        @media screen and (max-width:1300px) {
                            max-height: 330px;
                            width: 300px;
                        }

                        @media screen and (max-width:920px) {
                            margin: auto auto 30px auto;
                            width: 250px;
                            height: 250px;
                        }

                        @media screen and (max-width:420px) {
                            width: 150px;
                            height: 150px;
                        }

                        .hover-images {
                            display: none;
                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            height: 100%;
                        }

                        .main-img {
                            height: 100%;
                        }

                        .how-inner-card {
                            position: absolute;

                            .card-upper {
                                width: 400px;
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);

                                div {
                                    display: block;
                                    height: 200px;
                                    position: relative;
                                    z-index: 2;

                                    @media screen and (max-width:1500px) {
                                        height: 148px;
                                    }

                                    @media screen and (max-width:1400px) {
                                        height: 132px;
                                    }

                                    @media screen and (max-width:1300px) {
                                        height: 130px;
                                    }

                                    @media screen and (max-width:920px) {
                                        height: 100px;
                                    }

                                    @media screen and (max-width:420px) {
                                        height: 70px;
                                    }

                                    &.col1 {
                                        &:hover {
                                            &~.hover-image1-1 {
                                                display: block;
                                            }
                                        }
                                    }

                                    &.col2 {
                                        &:hover {
                                            &~.hover-image1-2 {
                                                display: block;
                                            }
                                        }
                                    }
                                }

                            }

                            .hover-images {
                                display: none;
                                position: absolute;
                                z-index: 1;
                                top: 0;
                                left: 0;
                                height: 100%;

                                @media screen and (max-width:1500px) {
                                    height: 350px;
                                }

                                @media screen and (max-width:1400px) {
                                    height: 320px;
                                }

                                @media screen and (max-width:1300px) {
                                    height: 330px;
                                }

                                @media screen and (max-width:920px) {
                                    height: 250px;
                                }

                                @media screen and (max-width:420px) {
                                    height: 150px;
                                }
                            }

                            .card-bottom {
                                width: 100%;

                                div {
                                    width: 100%;
                                    height: 200px;
                                    position: relative;
                                    z-index: 2;

                                    @media screen and (max-width:1500px) {
                                        height: 148px;
                                    }

                                    @media screen and (max-width:1400px) {
                                        height: 132px;
                                    }

                                    @media screen and (max-width:1300px) {
                                        height: 130px;
                                    }

                                    @media screen and (max-width:920px) {
                                        height: 100px;
                                    }

                                    @media screen and (max-width:420px) {
                                        height: 70px;
                                    }

                                    &.col3 {
                                        &:hover {
                                            &~.hover-image1-3 {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .howwork-col2 {
                        width: 100%;
                        max-height: 400px;
                        width: 400px;
                        position: relative;

                        @media screen and (max-width:1500px) {
                            max-height: 350px;
                            width: 350px;
                        }

                        @media screen and (max-width:1400px) {
                            max-height: 320px;
                            width: 320px;
                        }

                        @media screen and (max-width:1300px) {
                            max-height: 330px;
                            width: 300px;
                        }

                        @media screen and (max-width:920px) {
                            margin: auto auto 30px auto;
                            width: 250px;
                            height: 250px;
                        }

                        @media screen and (max-width:420px) {
                            width: 150px;
                            height: 150px;
                        }

                        .hover-images {
                            display: none;
                            position: absolute;
                            z-index: 1;
                            top: 0;
                            left: 0;
                            height: 100%;

                            @media screen and (max-width:1500px) {
                                height: 350px;
                            }

                            @media screen and (max-width:1400px) {
                                height: 320px;
                            }

                            @media screen and (max-width:1300px) {
                                height: 330px;
                            }

                            @media screen and (max-width:920px) {
                                height: 250px;
                            }

                            @media screen and (max-width:420px) {
                                height: 150px;
                            }
                        }

                        .main-img {
                            height: 100%;
                        }

                        .how-inner-card {
                            position: absolute;

                            .card-upper {
                                width: 400px;
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);

                                div {
                                    display: block;
                                    height: 200px;
                                    position: relative;
                                    z-index: 2;

                                    @media screen and (max-width:1500px) {
                                        height: 148px;
                                    }

                                    @media screen and (max-width:1400px) {
                                        height: 132px;
                                    }

                                    @media screen and (max-width:1300px) {
                                        height: 130px;
                                    }

                                    @media screen and (max-width:920px) {
                                        height: 100px;
                                    }

                                    @media screen and (max-width:420px) {
                                        height: 70px;
                                    }

                                    &.col1 {
                                        &:hover {
                                            &~.hover-image1-1 {
                                                display: block;
                                            }
                                        }
                                    }

                                    &.col2 {
                                        &:hover {
                                            &~.hover-image1-2 {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }

                            .card-bottom {
                                width: 100%;

                                div {
                                    width: 100%;
                                    height: 200px;
                                    position: relative;
                                    z-index: 2;

                                    @media screen and (max-width:1500px) {
                                        height: 148px;
                                    }

                                    @media screen and (max-width:1400px) {
                                        height: 132px;
                                    }

                                    @media screen and (max-width:1300px) {
                                        height: 130px;
                                    }

                                    @media screen and (max-width:920px) {
                                        height: 100px;
                                    }

                                    @media screen and (max-width:420px) {
                                        height: 70px;
                                    }

                                    &.col3 {
                                        &:hover {
                                            &~.hover-image1-3 {
                                                display: block;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.hover-token-b{
                        .hover-images{
                            display: block !important;
                        }
                    }

                }
            }
        }

        .slide-4 {
            min-height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            max-width: 1410px;
            margin: auto;
            width: 90%;

            @media screen and (max-width:1500px) {
                max-width: 1050px;
            }

            h1 {
                font-size: 3.5rem;
                margin-bottom: 1.5rem;

                @media screen and (max-width:920px) {
                    font-size: 2rem;
                }

                @media screen and (max-width:767px) {
                    font-size: 1.4rem;
                }
            }

            p {
                font-weight: 300;
                font-size: 1.6rem;
                max-width: 680px;
                line-height: 1.2;

                @media screen and (max-width:767px) {
                    font-size: 1rem;
                }
            }

            ul {
                &.social-links {
                    margin-left: auto;
                    margin-right: auto;
                    list-style-type: none;
                    padding-left: 0;
                    margin-top: 1rem;

                    li {
                        &.circle-items {
                            width: 90px;
                            height: 90px;
                            border-radius: 90px;
                            padding: 4px;
                            border: 4px solid $secondary-color;
                            margin: auto 1.2rem;
                            display: inline-block;
                            position: relative;
                            transition: all ease-in 0.2s;

                            @media screen and (max-width:767px) {
                                width: 60px;
                                height: 60px;
                                border-width: 2px;
                            }

                            @media screen and (max-width:576px) {
                                width: 40px;
                                height: 40px;
                            }

                            &::after {
                                content: ' ';
                                position: absolute;
                                top: -4px;
                                left: 15px;
                                width: 15px;
                                height: 15px;
                                border-radius: 15px;
                                background: $secondary-color;
                                display: block;

                                @media screen and (max-width:767px) {
                                    width: 13px;
                                    height: 13px;
                                }

                                @media screen and (max-width:576px) {
                                    width: 8px;
                                    height: 8px;
                                }
                            }

                            .circle-items-inner {
                                background: rgba($dark-color, 0.5);
                                width: 100%;
                                height: 100%;
                                border-radius: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                position: relative;
                                padding: 0;

                                @media screen and (max-width:767px) {
                                    padding: 0;
                                }

                                .icon {
                                    fill: $secondary-color;
                                    width: 50%;
                                    transition: all ease-in 0.2s;
                                }
                            }

                            &:hover {
                                border-color: #52B788;

                                &::after {
                                    background: #52B788;
                                }

                                .circle-items-inner {
                                    .icon {
                                        transform: scale(0.8);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-tooltip {
    &.token-overlay {
        .ant-tooltip-arrow {
            .ant-tooltip-arrow-content {
                background: #1D1F1E !important;
            }
        }

        .ant-tooltip-inner {
            background: #1D1F1E !important;
            box-shadow: 0px 5.12775px 5.12775px rgba(0, 0, 0, 0.25);
            border: 1px solid rgba(#95D5B2, 0.2);
            font-size: $font-size-sm - 2;
            min-height: 20px;
            padding: 2px 7px;
            border-radius: 10px;
            color: $white;
        }
    }
}

.token-overlay {
    max-width: 480px !important;
    width: 100%;
    left: 0 !important;
    right: 0 !important;
    margin: auto;

    &.small-overlay {
        max-width: 250px !important;
    }

    .ant-tooltip-inner {
        padding: 10px !important;
        border: 1px solid rgba($white, 0.3);

        @media screen and (max-height:990px) {
            margin-bottom: 80px;
        }
    }

}

.token-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .middle-arrow {
        width: 24%;

        .icon {
            width: 100%;
            fill: $white;
        }
    }

    .tokencard-col {
        padding: 8px;
        width: 38%;

        label {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
            width: 100%;
            text-align: center;
            display: block;
            margin-top: 10px;
            line-height: 1;
        }

        .token-down-arrow {
            height: 20px;
            width: 100%;
            fill: $white;
        }

        .tokencard {
            border-radius: 5px;
            width: 100%;
            text-align: center;
            padding: 8px 15px 5px;
            background: #313532;

            @media screen and (max-width:767px) {
                padding: 8px 10px 5px 10px;
            }

            &:nth-child(1) {
                margin-bottom: 10px;
            }

            .tokencard-icon {
                width: 34px;
                height: 34px;
                border-radius: 34px;
                border: 1.24px solid;
                background: linear-gradient(180deg, #50995b 0%, #032517 100%);
                padding: 1px;
                margin: auto;

                @media screen and (max-width:920px) {
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                }

                .icon {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                }
            }

            p {
                text-transform: uppercase;
                font-size: 14px;
                margin-top: 3px;
                line-height: 1.1;
                margin-top: 5px;
                margin-bottom: 5px;

                @media screen and (max-width:920px) {
                    font-size: $font-size-sm - 1;
                }
            }
        }
    }

    &.token-details-small {
        .tokencard-col {
            width: 100%;
        }
    }
}

.bottom-card-tooltip {
    position: fixed;
    padding: 20px;
    text-align: center;
    font-size: 1.3rem;
    background: rgba(232, 232, 232, 0.1);
    box-shadow: 0px 4px 6px rgba(32, 32, 32, 0.25);
    border: 1px solid #E2F7E5;
    border-radius: 10px;
    bottom: 5px !important;
    left: 0 !important;
    right: 0 !important;
    top: auto !important;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;

    @media screen and (max-width:1600px) {
        padding: 6px 8px;
        font-size: 1rem;
    }

    @media screen and (max-width:767px) {
        font-size: $font-size-sm;
    }

    @media screen and (max-width:420px) {
        font-size: $font-size-sm - 1;
    }
}

.ant-tooltip {
    &.bottom-tooltip {
        position: fixed;
        text-align: center;
        font-size: 1.3rem;
        border-radius: 10px;
        bottom: 5px;
        left: 0 !important;
        right: 0 !important;
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;
        max-height: 80px;
        width: 100%;
        text-align: center;

        @media screen and (max-width:1600px) {
            font-size: 1rem;
            bottom: -18px;
        }

        .ant-tooltip-inner {
            background: none;
            border: 0;
            box-shadow: none;
            background: rgba(232, 232, 232, 0.1);
            box-shadow: 0px 4px 6px rgba(32, 32, 32, 0.25);
            border: 1px solid #E2F7E5;
            padding: 20px;
            width: 100%;
            text-align: center;

            @media screen and (max-width:1600px) {
                padding: 6px 8px;
            }
        }
    }
}